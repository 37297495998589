.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.actions:hover {
  color: #fff
}

.react-bootstrap-table table {
  table-layout: auto;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .centeredTwo {
    position: fixed;
    top: 60%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }


  .modal {

    width: 10px;
    border-radius: 0px;
      overflow: hidden;
  }

  